import useProvider from '@/hooks/provider'
import { SaleLineRepository, SalePaymentRepository, SaleRepository } from '@/models/repository/salerepository'
import { CommercialState, CommercialStore, ISendParams, ITransferParams } from '../commercial'
import { ActionTree, Store as VuexStore } from 'vuex'
import { DocumentLoadingType } from '@/stores/document'
import { InjectionKey } from 'vue'
import { v4 } from 'uuid'

export interface SaleState extends CommercialState<SaleRepository> {

}

export const saleStoreKey: InjectionKey<VuexStore<SaleState>> = Symbol()

export class SaleStore extends CommercialStore<SaleRepository, SaleState> {
    static _saleStore: SaleStore;

    public async saveDraftTransferRepository(repository: SaleRepository, params: ITransferParams): Promise<any> {
        const provider = useProvider();

        return await provider.sale.saveDraftTransfer(repository, params.journalUid, params.lines);
    }

    public async transferRepository(repository: SaleRepository, params: ITransferParams): Promise<any> {
        const provider = useProvider();

        return await provider.sale.transfer(repository, params.journalUid, params.lines, params.payment);
    }

    public async sendRepository(repository: SaleRepository, params: ISendParams): Promise<any> {
        const provider = useProvider();

        return await provider.sale.send(repository,
            params.to,
            params.subject,
            params.body,
        );
    }

    public async previewRepository(repository: SaleRepository): Promise<any> {
        const provider = useProvider();

        return await provider.sale.preview(repository.header.model.uid!);
    }

    public async saveRepository(repository: SaleRepository): Promise<any> {
        const provider = useProvider();

        return await provider.sale.save(repository);
    }

    public cancelRepository(repository: SaleRepository): void {
        repository!.lines.forEach((line: SaleLineRepository) => {
            line.cancel()
            repository.computeLine(line)
        })
        
        repository.header.compute(repository!.lines)

        repository!.payments.forEach((payment: SalePaymentRepository) => {
            const cancelPayment = new SalePaymentRepository({
                uid: v4(),
                amount: payment.amount * -1,
                date: new Date(),
                payment_type_id: payment.paymentTypeId
            })
            repository.addPayment(cancelPayment)
        })

        repository!.header.model.fully_transferred = true
    }

    public duplicateRepository(repository: SaleRepository): SaleRepository {
        repository.header.model.id = undefined
        repository.header.model.uid = v4()
        repository.header.model.number = undefined 
        repository.header.model.fully_transferred = false 

        repository.lines.forEach((line: SaleLineRepository) => {
            const newUid = v4()

            repository.lines
                .filter((line: SaleLineRepository) => line.model.parent_sale_line_uid === line.model.uid)
                .forEach((line: SaleLineRepository) => line.model.parent_sale_line_uid = newUid)

            line.model.id = undefined
            line.model.uid = newUid
        })

        repository.payments.forEach((payment: SalePaymentRepository) => {
            payment.model.uid = v4()
        })

        repository.header.edited = true

        return repository
    }

    private async depositRepository(repository: SaleRepository, amount: number,): Promise<any> {
        const provider = useProvider();

        return await provider.sale.deposit(repository.header.model.uid!, amount);
    }

    public async getJSONRepository(documentHeaderUid: string): Promise<any> {
        const provider = useProvider();

        return await provider.sale.getSaleJSON(documentHeaderUid);
    }

    public async initializeRepository(repository: SaleRepository): Promise<SaleRepository> {
        await repository.fetchJournal().then(journal => {
            repository.header.model.store_id = journal.store_id;
            repository.header.customerUid = journal.invoice_customer_uid;
            repository.header.edited = false;
        });

        return repository;
    }

    public async getRepository(documentHeaderUid: string): Promise<SaleRepository> {
        const provider = useProvider();

        return await provider.sale.getSaleRepository(documentHeaderUid);
    }

    getActions(): ActionTree<SaleState, SaleState> {
        const that = this;

        return {
            ...super.getActions(),
            async deposit(store, { amount }): Promise<SaleRepository | null> {
                store.commit("addLoading", DocumentLoadingType.Saving);

                const repository = store.state.repository;

                if (!repository) return null;

                console.log("deposit sale", repository);

                return new Promise((resolve, reject) => {
                    that.depositRepository(repository, amount).then(async (response: any) => {

                        const s = await that.getJSONRepository(response.to_document_uid);

                        store.state.repository?.clear();

                        store.state.repository?.fromJson(s);

                        resolve(store.state.repository);
                    }).catch((error: any) => {
                        console.log("error from deposit call", error)
                        reject(error);

                    }).finally(() => {
                        store.commit("removeLoading", DocumentLoadingType.Saving);
                    });
                });
            },
        }
    }

    public static useStore() {
        if (!SaleStore._saleStore)
            SaleStore._saleStore = new SaleStore();

        return SaleStore._saleStore.useStore();
    }
}
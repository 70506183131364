import POSKiosk from '@/models/poskiosk';

export enum SaleViewRegroupMode {
    NONE,
    RANK,
    SENT_TIME,
    USER_AND_SENT_TIME,
    USER
}

export enum VivawalletMode {
    ECR = 1,
    EMBEDDED = 2,
    API = 3
}

export default class POS {
    public uid?: string;
    public id?: number;
    public device_id?: string;
    public store_id?: number;
    public name?: string;
    public license_code?: string;
    public queue_screen_sound?: string;
    public queue_screen_active?: boolean;
    public queue_screen_sound_name?: string;
    public queue_screen_sound_base64?: string;
    public rfid_reader_type?: number;
    public rfid_usb_reader_name?: string;
    public rfid_display_mode?: string;
    public entity_id?: number;
    public pos_kiosk?: POSKiosk;
    public prefix_number?: string;

    public regroup_same_item_on_print_dispatch?: boolean;
    public regroup_same_item?: boolean;
    public sale_view_regroup_same_item?: boolean;
    public sale_view_regroup_mode?: SaleViewRegroupMode;
    public show_cancel_line?: boolean;
    public create_kitchen_tasks?: boolean;
    public remove_rank_on_order_delivery?: boolean;
    public vivawallet_mode?: VivawalletMode;
    public vivawallet_ip?: string;
    public vivawallet_port?: number;
    public vivawallet_embedded?: boolean;
    public vivawallet_print_embedded?: boolean;
    public vivawallet_terminal_id?: number;
    public vivawallet_api_terminal_merchant_id?: string;
    public vivawallet_api_user_name?: string;
    public vivawallet_api_password?: string;
    public customer_webapp_allow_refund?: boolean;
    public customer_webapp_login_type?: number;
    public customer_webapp_allow_credit?: boolean;

    public proposition_max_reached_mode?: number;

    public voucher_printing_confirmation?: boolean = false
    public show_general_button_login_with_pincode?: boolean = false
    public active_multi_selection_item?: boolean = false
    public show_detail_turnover_on_xz_report?: boolean = true
    public license_invoicing_id?: number;
    public license_invoicing_note?: string;
    public fdm_request: boolean = false
    public fdm_installation: boolean = false

    constructor(init?: Partial<POS>) {
        this.rfid_reader_type = 0;
        Object.assign(this, init);
    }
}

import { IDocumentRepository } from '@/models/repository/documentrepository'
import { InjectionKey } from 'vue'
import { Store, StoreOptions, MutationTree, GetterTree, ActionTree, createStore, } from 'vuex'

export type IDocumentState = DocumentState<IDocumentRepository>;

export interface DocumentState<D extends IDocumentRepository> {
    repository: D | null,
    loading: DocumentLoadingType[],
}

export enum DocumentLoadingType {
    Document,
    Saving,
    Preview,
    Cancel,
    Duplicate
}

export abstract class DocumentStore<D extends IDocumentRepository, S extends DocumentState<D>> {
    public abstract getRepository(documentHeaderUid: string): Promise<D>;

    public abstract initializeRepository(repository: D): Promise<D>;

    public abstract saveRepository(repository: D): Promise<any>;

    public abstract getJSONRepository(documentHeaderUid: string): Promise<any>;

    public abstract previewRepository(repository: D): Promise<any>;

    public abstract cancelRepository(repository: D): void

    public abstract duplicateRepository(repository: D): D

    key: InjectionKey<Store<S>> = Symbol();

    getState(): DocumentState<D> {
        return {
            repository: null,
            loading: [],
        };
    }

    getMutations(): MutationTree<S> {
        return {
            setRepository(state, repository: D) {
                state.repository = repository;
            },
            addLoading(state, loading: DocumentLoadingType) {
                if (!state.loading.includes(loading))
                    state.loading.push(loading);
            },
            removeLoading(state, loading: DocumentLoadingType) {
                state.loading.splice(state.loading.indexOf(loading), 1);
            },
        };
    }

    getGetters(): GetterTree<S, S> {
        return {
            isLoading(state) {
                return state.loading.length > 0;
            },
            containsLoading: (state) => (loading: DocumentLoadingType | DocumentLoadingType[]): boolean => {
                if (Array.isArray(loading)) return state.loading.some(l => loading.includes(l));

                return state.loading.includes(loading);
            },
            edited: (state) => (): boolean | undefined => {
                return state.repository?.isEdited;
            }
        };
    }

    getActions(): ActionTree<S, S> {
        const that = this;

        return {
            clear(store): boolean {
                store.state.repository = null;

                return true;
            },
            async fetchNewRepository(store, repository: D): Promise<D> {
                store.commit("addLoading", DocumentLoadingType.Document);

                store.dispatch("clear");

                await that.initializeRepository(repository);

                store.commit("setRepository", repository);

                store.commit("removeLoading", DocumentLoadingType.Document);

                if (repository == null) return Promise.reject(repository);

                return Promise.resolve(repository);
            },
            async fetchRepository(store, documentHeaderUid: string): Promise<D> {

                if (store.state.repository) return Promise.resolve(store.state.repository);

                store.commit("addLoading", DocumentLoadingType.Document);

                store.dispatch("clear");

                const s = await that.getRepository(documentHeaderUid);

                console.log("fetchRepository", s);

                store.commit("setRepository", s);

                store.commit("removeLoading", DocumentLoadingType.Document);

                if (s == null) return Promise.reject(s);

                return Promise.resolve(s);
            },
            async save(store): Promise<D | null> {
                store.commit("addLoading", DocumentLoadingType.Saving);

                const repository = store.state.repository;

                if (!repository) return null;

                console.log("save repository", repository);

                return new Promise((resolve, reject) => {
                    that.saveRepository(repository).then(async (response: any) => {

                        const s = await that.getJSONRepository(response.uid);

                        store.state.repository?.clear();

                        store.state.repository?.fromJson(s);

                        resolve(store.state.repository);
                    }).catch((error: any) => {
                        console.log("error from save call", error)
                        reject(error);

                    }).finally(() => {
                        store.commit("removeLoading", DocumentLoadingType.Document);
                    });

                    // return resolve(store.state.repository);
                });
            },
            async preview(store): Promise<string | null> {
                store.commit("addLoading", DocumentLoadingType.Preview);

                const repository = store.state.repository;

                if (!repository) return null;

                console.log("preview repository", repository);

                return new Promise((resolve, reject) => {
                    that.previewRepository(repository).then(async (response: any) => {

                        console.log("preview", response)

                        resolve(response);
                    }).catch((error: any) => {
                        console.log("error from preview call", error)
                        reject(error);

                    }).finally(() => {
                        store.commit("removeLoading", DocumentLoadingType.Preview);
                    });
                });
            },
            async cancel(store): Promise<D | null> {
                store.commit("addLoading", DocumentLoadingType.Cancel);

                const repository = store.state.repository;

                if (!repository) return null;

                that.cancelRepository(repository)

                store.commit("removeLoading", DocumentLoadingType.Cancel);

                return await store.dispatch('save')
            },
            async duplicate(store): Promise<D | null> {
                store.commit("addLoading", DocumentLoadingType.Duplicate)

                const repository = store.state.repository

                if (!repository) return null

                return new Promise((resolve, reject) => {
                    try {
                        that.duplicateRepository(repository)
                        
                        resolve(store.state.repository!)
                    } catch (error) {
                        reject(error)
                    } finally {
                        store.commit("removeLoading", DocumentLoadingType.Duplicate)
                    }
                })
            }
        };
    }

    store: StoreOptions<S> = {
        state: this.getState() as S,
        mutations: this.getMutations(),
        getters: this.getGetters(),
        actions: this.getActions(),
    }

    public useStore() {
        return createStore(this.store);
    }
}
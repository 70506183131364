<template>
  <div class="bo-selector bo--selector-store">
    <i class="fas fa-store-alt" />
    <MultiSelect
        aria-autocomplete="none"
        class="bo-fullwidth" style="z-index: 10000"
        :options="globalState.getters.availableStores"
        :optionLabel="localize.field('name')"
        placeholder="Store"
        optionValue="id"
        v-model="componentStores"
        @hide="onHide"
    >
    </MultiSelect>
  </div>
</template>
<script>
import {computed, defineComponent, onMounted, reactive, ref, watch} from "vue";
import {globalState} from '@/states/global';
import filters from "@/helpers/filters";
import useLocalization from "@/hooks/localization";
import {deepCompare} from "@/helpers/deepCopy";
export default defineComponent({
  setup() {
    const localize = useLocalization();

    const componentStores = ref();

    watch(() => globalState.state.selected.storeIds, (v) => {
      componentStores.value = v;
    })

    const onHide = () => {
      if (!deepCompare([componentStores.value, globalState.state.selected.storeIds]))
        globalState.dispatch('setStoreIds', componentStores.value);
    }

    return {
      filters,
      globalState,
      localize,
      componentStores, onHide
    }
  }
})
</script>
import { RegimeType } from "@/models/enums/regimetype.enum";
import { ConsumptionType } from "../enums/consumptiontype.enum";
import moment from "moment";
import { ItemDocumentHeader, ItemDocumentLine } from "./itemdocument";
import { DocumentLine } from "./document";

export abstract class CommercialLine extends ItemDocumentLine {
    discount_rate?: number;
    total_net_vat_included?: number;
    total_net_vat_excluded?: number;
    note?: string;
    vat_rate?: number;
    transferred_quantity?: number;
    quantity_to_transfer?: number;
    original_quantity?: number;
    quantity_cancel?: number;

    fromJson(init: any): DocumentLine {
        super.fromJson(init);

        this.discount_rate = init["discount_rate"] ?? undefined;
        this.total_net_vat_included = init["total_net_vat_included"] ?? undefined;
        this.total_net_vat_excluded = init["total_net_vat_excluded"] ?? undefined;
        this.note = init["note"] ?? undefined;
        this.vat_rate = init["vat_rate"] ?? undefined;
        this.transferred_quantity = init["transferred_quantity"] ?? undefined;
        this.quantity_to_transfer = init["quantity_to_transfer"] ?? undefined;
        this.original_quantity = init["original_quantity"] ?? undefined;
        this.quantity_cancel = init["quantity_cancel"] ?? undefined;

        return this;
    }
}

export abstract class CommercialPayment {
    uid?: string;
    payment_type_id?: number;
    amount: number = 0;
    date?: Date;

    constructor(init?: Partial<CommercialPayment>) {
        Object.assign(this, init);
    }

    fromJson(init: any): CommercialPayment {
        console.log("CommercialPayment.fromJson", init);

        this.uid = init["uid"] ?? undefined;
        this.payment_type_id = init["payment_type_id"] ?? undefined;
        this.amount = init["amount"] ?? undefined;
        this.date = init["date"] ? new Date(init["date"]) : undefined;
        
        console.log("END CommercialPayment.fromJson", this);

        return this;
    }

    toJson(): any {
        return {
            ...this,
            date : this.date
                ? moment(this.date)
                    .set('year', this.date.getFullYear())
                    .set('month', this.date.getMonth())
                    .set('date', this.date.getDate())
                    .toISOString(true)
                : undefined,
        }
    }
}

export abstract class CommercialHeader extends ItemDocumentHeader {
    reference?: string;
    store_id?: number;
    regime_type: RegimeType = RegimeType.Normal;
    deadline?: Date;
    total_vat_included: number = 0;
    total_vat_excluded: number = 0;
    consumption_type: ConsumptionType = ConsumptionType.NONE;
    price_list_uid?: string;
    fully_transferred: boolean = false;
    accounting: boolean = false;
    accounting_creation?: Date;
    currency_id: number = 1;
    note?: string;
    duty_active: boolean = false;
    payment_term_id?: number;
    document_status_id?: number;
    intrastat_transaction_type_id?: number;

    fromJson(init: any): CommercialHeader {
        super.fromJson(init);

        console.log("CommercialDocument.fromJson");

        this.reference = init["reference"] ?? undefined;
        this.store_id = init["store_id"] ?? undefined;
        this.regime_type = Number(init["regime_type"]);
        this.deadline = init["deadline"] ? new Date(init["deadline"]) : undefined;
        this.total_vat_included = Number(init["total_vat_included"]);
        this.total_vat_excluded = Number(init["total_vat_excluded"]);
        this.consumption_type = Number(init["consumption_type"]);
        this.price_list_uid = init["price_list_uid"] ?? undefined;
        this.fully_transferred = Boolean(init["fully_transferred"]);
        this.accounting = Boolean(init["accounting"]);
        this.currency_id = Number(init["currency_id"]);
        this.note = init["note"] ?? undefined;
        this.duty_active = Boolean(init["duty_active"]);
        this.payment_term_id = init["payment_term_id"] ?? undefined;
        this.document_status_id = init["document_status_id"] ?? undefined;
        this.intrastat_transaction_type_id = init["intrastat_transaction_type_id"] ?? undefined;
        this.accounting_creation = init["accounting_creation"] ? new Date(init["accounting_creation"]) : undefined;

        return this;
    }

    toJson(): any {
        return {
            ...super.toJson(),
            deadline: this.deadline ?
                moment(this.deadline)
                    .set('year', this.deadline.getFullYear())
                    .set('month', this.deadline.getMonth())
                    .set('date', this.deadline.getDate())
                    .toISOString(true) :
                undefined,
        }
    }
}